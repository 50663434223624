import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, doc, updateDoc } from 'firebase/firestore';
import { dbFirestore, auth, signOut } from '../../firebase';
import {  getAuth, EmailAuthProvider, reauthenticateWithCredential, updatePassword, verifyBeforeUpdateEmail } from 'firebase/auth';
import { Link } from "react-router-dom";
import { Icon } from '@iconify/react';
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';
import Tooltip from '../Tooltip';


function UserInfo() {
    const [users, setUsers] = useState([]);
    const { userId } = useParams();
    const [createdAt, setCreatedAt] = useState('');
    const [user, setUser] = useState(null);
    const [email, setEmail] = useState('');

    const [expandedRows, setExpandedRows] = useState({});

    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(3);

    const navigate = useNavigate();

    useEffect(() => {
      // Listen for authentication state changes
      const unsubscribe = auth.onAuthStateChanged((user) => {
        if (user) {
          // User is signed in, update the user state
          setUser(user);
        } else {
          // User is signed out, redirect to sign-in page
          navigate('/sign-in'); // Replace '/signin' with your actual sign-in route
        }
      });
  
      // Clean up the subscription to avoid memory leaks
      return () => unsubscribe();
    }, [navigate]);
  
     // Function to handle user logout
     const handleLogout = async () => {
      try {
        await signOut(auth);
        navigate('/sign-in'); // Redirect to the login page after logout
      } catch (error) {
        console.error('Error logging out:', error);
      }
    };


    


    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const usersRef = collection(dbFirestore, 'account');
                const usersSnapshot = await getDocs(usersRef);
                const usersData = usersSnapshot.docs.map(doc => {
                    const userData = doc.data();

                    const isEmailVerified = userData.isEmailVerified;

                    // Assuming 'disabled' field exists in the user document
                    const isAuthEnabled = !userData.disabled;
                    // Convert Firestore Timestamp to JavaScript Date object
                    const createdAtTimestamp = userData.createdAt.toDate();
                    // Format the date as "Month Year"
                    const monthYear = createdAtTimestamp.toLocaleString('default', { month: 'long' }) + ' ' + createdAtTimestamp.getFullYear();

                    const email = userData.email;
                    

                    return {
                        id: doc.id,
                        ...userData,
                        // Assuming 'disabled' field exists in the user document
                        isAuthEnabled: !userData.disabled, // Check if user account is enabled
                        memberSince: monthYear,
                        userEmail: email
                        

                    };

                    
                    return {
                        id: doc.id,
                        ...userData,
                        isAuthEnabled,
                        isEmailVerified,
                        userEmail: auth.email
                       
                        
                    };
                });
                setUsers(usersData);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };

        fetchUsers();
    }, [])



    

    // Function to extract month and year from createdAt field
  const extractMonthYear = (createdAt) => {
    if (!createdAt) return '';

    const date = new Date(createdAt);
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    return `${month} ${year}`;
  };

  
  const toggleAuthStatus = async (userId, isAuthEnabled) => {
    try {
        const userDocRef = doc(dbFirestore, 'account', userId);
        await updateDoc(userDocRef, {
            disabled: !isAuthEnabled // Invert the current authentication status
        });
        // Update the local state to reflect the change
        setUsers(users.map(user => {
            if (user.id === userId) {
                return { ...user, isAuthEnabled: !isAuthEnabled, disabled: !isAuthEnabled }; // Update isAuthEnabled and disabled fields
            }
            return user;
        }));
    } catch (error) {
        console.error('Error toggling authentication status:', error);
    }
};


    const profileStyle = {
        margin: '5px',
        padding: '6px 12px',
        fontSize: '15px',
        border: 'none',
        borderRadius: '5px',
        backgroundColor: 'blue',
        color: 'white',
        cursor: 'pointer'
      };

      const renderUsersList = () => {
  
        // Sort the domains by timestamp in descending order
      const sortedDomains = users.slice().sort((a, b) => {
        // Assuming each domain entry has a timestamp field called 'timestamp'
        return new Date(b.timestamp) - new Date(a.timestamp);
      });
        const isSmallScreen = window.innerWidth <= 768;
    
        const usersToDisplay = sortedDomains.slice(startIndex, endIndex);
    
        
        const thStyle = {
          backgroundColor: '#f0f0f0',
          padding: '8px',
          textAlign: 'left',
          borderBottom: '1px solid #ddd',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          maxWidth: isSmallScreen ? '150px' : '200px',
        };
    
        const tdStyle = {
          padding: '8px',
          borderBottom: '1px solid #ddd',
          backgroundColor: '#ffffff',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          maxWidth: isSmallScreen ? '100px' : '200px',
        };
    
        const TooltipbtnStyle = {
          backgroundColor: '#ffffff',
          color: '#000000',
          padding: '2px 4px', // Adjust padding as needed for your content
          border: 'none',
          borderRadius: '5px', // Border radius for rounded corners
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center', // Center content vertically
          justifyContent: 'center', // Center content horizontally
         
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', // Optional box shadow for a raised effect
        };
    
        const toggleRowExpansion = (domainId) => {
          setExpandedRows((prevExpandedRows) => ({
            ...prevExpandedRows,
            [domainId]: !prevExpandedRows[domainId],
          }));
        };
    
        const tableStyle = {
          borderCollapse: 'collapse',
          width: '100%',
          textAlign: 'left',
      
        };

        const expandbtnStyle = {
            margin: '5px',
            padding: '6px 12px',
            fontSize: '12px',
            border: 'none',
            borderRadius: '5px',
            backgroundColor: 'blue',
            color: 'white',
            cursor: 'pointer'
          };

        return (

      
            <table style={tableStyle}>
             
              <thead>
                <tr>
                  <th style={thStyle}>User No.</th>
                  <th style={thStyle}>Full Name</th>
                  <th style={thStyle}>Email</th>
                  
                  {isSmallScreen && <th style={thStyle}></th>}
                  {!isSmallScreen && (
                    <>
                      <th style={thStyle}>User Type</th>
                      <th style={thStyle}>Physical Address</th>
                      <th style={thStyle }>Profile</th>
                      <th style={thStyle}>Date Joined</th>
                      <th style={thStyle}>Verification</th>
                      <th style={thStyle}>Account Status</th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {usersToDisplay.map((user, index) => {
                  const rows = [
                    <tr key={user.id}>
                      
                      <td style={tdStyle}>{index + 1}</td>
                      <td style={tdStyle}>{user.fullName}</td>
                      <td style={tdStyle}>{user.email}</td>
                      {isSmallScreen && (
                        <td style={tdStyle}>
                          <button style={ expandbtnStyle } className="expand-button" onClick={() => toggleRowExpansion(users.id)}>
                            {expandedRows[users.id] ? '-' : '+'}
                          </button>
                        </td>
                      )}
                      {!isSmallScreen && (
                        <>
                          <td style={tdStyle}>{user.userType}</td>
                          <td style={{ padding: '8px', borderBottom: '1px solid #ddd', backgroundColor: '#ffffff', overflow: 'visible', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '200px', }}>
                      <Tooltip text={user.address || 'No Address is provided'}>
                        <button style={TooltipbtnStyle}>Read description</button>
                      </Tooltip>
                    </td>
                          <td style={tdStyle}><Link to={`/profile/${user.id}`}>
    <button style={profileStyle}>Profile</button>
</Link></td>
                          <td>{user.memberSince} </td>
                          <td style={tdStyle}>{user.isEmailVerified ? 'Verified' : 'Not Verified'}</td>
                          <td style={tdStyle}>{user.isAuthEnabled ? 'Enabled' : 'Disabled'}
                          <button onClick={() => toggleAuthStatus(user.id, user.isAuthEnabled)}>
                                {user.isAuthEnabled ? 'Disable' : 'Enable'}
                            </button> </td>
                        </>
                      )}
                    </tr>
                  ];
        
                  if (isSmallScreen && expandedRows[users.id]) {
                    rows.push(
                      <tr key={`${users.id}-expanded`}>
                        <td colSpan={isSmallScreen ? '3' : '8'}>
                          <div>
                          <p style={{ color: 'grey', textAlign: 'center', lineHeight: '1.5' }}>
        <strong>{user.fullName}</strong>
      </p>
                            <p><strong>User Type:</strong> {user.userType}</p>
                            <p><strong>Physical Address:</strong> {user.address}</p>
                            <p><strong>Profile:</strong> <Link to={`/profile/${user.id}`}>
    <button style={profileStyle}>Profile</button>
</Link></p>
                            <p><strong>Date Joined:</strong> {user.memberSince}</p>
                            <p><strong>Verification:</strong> {user.isEmailVerified ? 'Verified' : 'Not Verified'}</p>
                            <p><strong>Account Status:</strong> {user.isAuthEnabled ? 'Enabled' : 'Disabled'}
                            <button onClick={() => toggleAuthStatus(user.id, user.isAuthEnabled)}>
                                {user.isAuthEnabled ? 'Disable' : 'Enable'}
                            </button></p>
                    
                          
                          </div>
                        </td>
                      </tr>
                    );
                  }
        
                  return rows;
                })}
              </tbody>
            </table>
      
      
          );
        };
    
        
        
          const NxtNavbuttonStyle = {
        
            position: 'absolute',
          
            right: '10px',
           
            
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'right', 
           
              margin: '0 5px', // Margin between buttons
              padding: '8px', // Padding inside buttons
              cursor: 'pointer', // Cursor style
              backgroundColor: 'transparent',
            };
          
            const PreNavbuttonStyle = {
          
              position: 'absolute',
          
            right: '40px',
           
            
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'right', 
           
              margin: '0 5px', // Margin between buttons
              padding: '8px', // Padding inside buttons
              cursor: 'pointer', // Cursor style
              backgroundColor: 'transparent',
            };
            
            const disabledNxtNavButtonStyle = {
              ...NxtNavbuttonStyle,
          
              cursor: 'not-allowed', // Change cursor to not-allowed for disabled buttons
              color: 'grey', // Set text color to grey for disabled buttons
          
            alignItems: 'center',
            justifyContent: 'center',
            };
          
            const disabledPreNavButtonStyle = {
              ...PreNavbuttonStyle,
          
              cursor: 'not-allowed', // Change cursor to not-allowed for disabled buttons
              color: 'grey', // Set text color to grey for disabled buttons
          
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'transparent',
            };
            
            
            const NaviconStyle = {
              marginRight: '5px', // Margin between icon and text
            };
          
            
            const handlePreviousPage = () => {
              const newStartIndex = Math.max(startIndex - 3, 0);
              setStartIndex(newStartIndex);
              setEndIndex(newStartIndex + 3);
            };
        
            const handleNextPage = () => {
              const newStartIndex = endIndex;
              const newEndIndex = Math.min(endIndex + 3, users.length);
              setStartIndex(newStartIndex);
              setEndIndex(newEndIndex);
            };    

    return (

        <div>
            <h2>Users

            <button
  style={startIndex <= 0 ? disabledPreNavButtonStyle : PreNavbuttonStyle}
  onClick={handlePreviousPage}
  disabled={startIndex <= 0}
>
  <Icon icon="carbon:previous-filled" color={startIndex <= 0 ? 'grey' : 'black'} width="24" height="24" style={NaviconStyle} />

</button>

<button
  style={endIndex >= users.length ? disabledNxtNavButtonStyle : NxtNavbuttonStyle}
  onClick={handleNextPage}
  disabled={endIndex >= users.length}
>
  <Icon icon="carbon:next-filled" color={endIndex >= users.length ? 'grey' : 'black'} width="24" height="24" style={NaviconStyle} />
</button>
            </h2>
            {renderUsersList()}
        </div>
    );
}

export default UserInfo;
