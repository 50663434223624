// src/ScheduleTable.js
import React from 'react';



const Income = () => {
    const schedule = {
        Monday: { activity: 'Gym', time: '6:00 AM' },
        Tuesday: { activity: 'Swimming', time: '7:00 AM' },
        Wednesday: { activity: 'Yoga', time: '8:00 AM' },
        Thursday: { activity: 'Cycling', time: '6:30 AM' },
        Friday: { activity: 'Running', time: '7:30 AM' }
      };
      

  return (
    <table className="schedule-table">
      <thead>
        <tr>
          <th>Day</th>
          <th>Activities</th>
          <th>Time</th>
        </tr>
      </thead>
      <tbody>
        {Object.entries(schedule).map(([day, { activity, time }]) => (
          <tr key={day}>
            <td>{day}</td>
            <td>{activity}</td>
            <td>{time}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Income;
