// src/firebase.js

import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged, signOut, updatePassword, sendEmailVerification } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';
import { getStorage } from "firebase/storage";



// Initialize Firebase
const firebaseConfig = {
    apiKey: "AIzaSyCZDicE3rJhfIRAp6pN20S6LMjuMxrvR4o",
    authDomain: "website-business-program.firebaseapp.com",
    projectId: "website-business-program",
    storageBucket: "website-business-program.appspot.com",
    messagingSenderId: "355849783597",
    appId: "1:355849783597:web:010b4069988bf68c24eba4",
    measurementId: "G-BLY7XF352G"
  };

const app = initializeApp(firebaseConfig);
const auth = getAuth(app); // Initialize auth with the app instance
const dbFirestore = getFirestore(app); // Initialize dbFirestore with the app instance
const storage = getStorage(app);


export { auth, dbFirestore, storage, onAuthStateChanged, signOut, updatePassword, sendEmailVerification }; // Export auth and dbFirestore directly
export default app;