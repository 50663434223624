// src/components/AccountSettings.js

import React, { useState, useEffect } from 'react';
import { doc, getDoc, setDoc, deleteDoc, collection, query, where, getDocs, onSnapshot } from 'firebase/firestore';
import { auth, dbFirestore, sendEmailVerification, signOut, storage } from '../firebase';
import {  getAuth, EmailAuthProvider, reauthenticateWithCredential, updatePassword, verifyBeforeUpdateEmail } from 'firebase/auth';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage'; 
import { deleteUser } from 'firebase/auth';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import Notification from '../Notification';
import "./AccountSettings.css";
import { Icon } from '@iconify/react';
import LoadingSpinner from '../LoadingSpinner';
import { useParams } from 'react-router-dom';
import 'firebase/storage';


const pageStyle = {
  backgroundColor: '#ffffff', // Replace with your desired background color
};

const AccountSettings = () => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate(); // Initialize useNavigate
  const [notification, setNotification] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { userId } = useParams();
  const [userType, setUserType] = useState('');
  const [fullName, setFullName] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [city, setCity] = useState('');
  const [address, setAddress] = useState('');

  const [displayName, setDisplayName] = useState('');
  const [chat, setChat] = useState('');
  const [call, setCall] = useState('');
  const [email, setEmail] = useState('');
  const [languages, setLanguages] = useState('');
  const [about, setAbout] = useState('');
  const [fileNameProfilePicture, setFileNameProfilePicture] = useState(null);
  const [fileNameCoverPhoto, setFileNameCoverPhoto] = useState(null);
  const [profilePicture, setProfilePicture] = useState(null);
  const [profilePictureURL, setProfilePictureURL] = useState('');
  const [coverPhoto, setCoverPhoto] = useState(null);
  const [coverPhotoURL, setCoverPhotoURL] = useState('');
  const [markedForDeletionProfilePicture, setMarkedForDeletionProfilePicture] = useState(false);
  const [markedForDeletionCoverPhoto, setMarkedForDeletionCoverPhoto] = useState(false);
  
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [newEmail, setNewEmail] = useState('');
  const [confirmNewEmail, setConfirmNewEmail] = useState('');
  const [newEmailPassword, setNewEmailPassword] = useState('');
  

  const [deletePassword, setDeletePassword] = useState('');
  const [confirmationText, setConfirmationText] = useState('');

  const [isEmailVerifiedState, setIsEmailVerified] = useState(false);
  
  
  
  useEffect(() => {
    // Listen for authentication state changes
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        // User is signed in, update the user state
        setUser(user);
      } else {
        // User is signed out, redirect to sign-in page
        navigate('/sign-in'); // Replace '/signin' with your actual sign-in route
      }
    });

    // Clean up the subscription to avoid memory leaks
    return () => unsubscribe();
  }, [navigate]);

  
   // Function to handle user logout
   const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/sign-in'); // Redirect to the login page after logout
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };


  useEffect(() => {
    if (user) {
      // Check if the user's email is verified
      setIsEmailVerified(user.emailVerified);

    }
  }, [user]);


  // For header information
  


  const handleSendEmailVerification = () => {
    const user = auth.currentUser;

    if (user) {
      sendEmailVerification(user)
        .then(() => {
          // Email verification sent successfully
          setNotification('Email was sent to verify Account. Please check your mailbox.');
          console.log('Email Verification sent! Check your mailbox.');
          // You can add further logic here, such as redirecting the user or showing a success message.
          
        })
        .catch(error => {
          // Handle errors here
          setNotification('Error sending email to verify Account: ' + error.message);
          console.error('Error sending email verification:', error);
        });
    } else {
      // No user is currently signed in
      setNotification('No user is currently signed in.');
      console.error('No user is currently signed in.');
    }
  };


  useEffect(() => {
    if (user) {
      const docRef = doc(dbFirestore, 'account', user.uid);
      setDoc(docRef, { isEmailVerified: isEmailVerifiedState }, { merge: true }) // Update Firestore document with verified status
        .catch(error => console.error('Error updating verified status in Firestore:', error));
    }
  }, [isEmailVerifiedState, user]);


  const handleCancelUploadProfilePicture = () => {
    
    setProfilePicture(null);
    setFileNameProfilePicture(null);
    
    // Set a flag to mark the current cover photo for deletion
    setMarkedForDeletionProfilePicture(true);

    // Delete the image from storage (assuming you have access to a delete function)
    deleteImageFromStorage(); // You need to implement this function

    function deleteImageFromStorage() {
      if (profilePictureURL) {
          const storageRef = ref(storage, profilePictureURL);
          deleteObject(storageRef).then(() => {
              console.log("Image deleted from storage successfully");
          }).catch((error) => {
              console.error("Error deleting image from storage:", error);
          });
      } else {
          console.error("Profile picture URL is null or undefined.");
      }
  }
  
};


 
  

const handleProfilePictureChange = (event) => {
  const selectedFile = event.target.files[0];

  // Update profilePicture state variable
  setProfilePicture(selectedFile);

  // Update fileName state variable with the uploaded file's name
  setFileNameProfilePicture(selectedFile.name);

  // Check if a file is selected
  if (selectedFile) {
    // Validate file type (accept only images)
    if (!selectedFile.type.startsWith('image/')) {
      alert('Please select a valid image file.');
      return;
    }

    // Validate file size (limit to 5 MB)
    const maxSize = 5 * 1024 * 1024; // 5 MB in bytes
    if (selectedFile.size > maxSize) {
      alert('File size exceeds the limit of 5 MB.');
      return;
    }

    // Additional file processing logic can be added here if needed

    // Reset the file input value after processing
    event.target.value = '';
  }
};




  const handleSaveProfile = async () => {
    try {
      if (user) {
        const docRef = doc(dbFirestore, 'users', userId);
  
       
        
        

        if (profilePicture) {
          // If a profile picture is selected by the user
          const profilePictureRef = ref(storage, `profilePictures/${userId}`);
          try {
            // Upload the profile picture to Firebase Storage
            await uploadBytes(profilePictureRef, profilePicture);
        
            // Generate a timestamp to ensure a unique URL every time the profile picture is updated
            const timestamp = new Date().getTime();
        
            // Get the download URL of the uploaded profile picture and append the timestamp as a query parameter
            const profilePictureURL = await getDownloadURL(profilePictureRef) + `?${timestamp}`;
        
            // Update profile picture URL in Firestore document
            await setDoc(docRef, { profilePictureURL }, { merge: true });
            console.log('Profile picture URL updated in Firestore');
          } catch (error) {
            console.error('Error uploading profile picture:', error);
            setNotification('Error uploading profile picture. Please try again.');
            return; // Exit the function to prevent further execution
          }
        } else {
          // If no profile picture is selected (user canceled the upload), update Firestore with an empty profilePictureURL
          await setDoc(docRef, { profilePictureURL: '' }, { merge: true });
          console.log('Profile picture URL deleted from Firestore');
        }
  
        // Reset the cover photo and file name states after successfully saving the profile
       
        setProfilePicture(null);
      
        setFileNameProfilePicture(null);
      
        setNotification('Profile successfully updated!');
        
        console.log('Profile successfully updated!');
      }
    } catch (error) {
      setNotification('Error updating profile');
      console.error('Error updating profile:', error);
    }
  };

  useEffect(() => {
    if (user) {
      const docRef = doc(dbFirestore, 'users', userId);

      getDoc(docRef)
        .then((docSnapshot) => {
          if (docSnapshot.exists()) {
            const userData = docSnapshot.data();
           
            setProfilePictureURL(userData.profilePictureURL || ''); // Set current profile picture URL
            
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.error('Error getting document:', error);
          setIsLoading(false);
        });
    }
  }, [user]);



  useEffect(() => {
    setIsLoading(true); // Set isLoading to true when data fetching starts
    if (userId) {
      const docRef = doc(dbFirestore, 'account', userId);
  
      const unsubscribe = onSnapshot(docRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          const userData = docSnapshot.data();
          setUserType(userData.userType);
          setFullName(userData.fullName);
          setContactNumber(userData.contactNumber);
          setCity(userData.city);
          setAddress(userData.address);
          setIsEmailVerified(userData.isEmailVerified);
        } else {
          // Handle the case where the document does not exist
          console.error('Document does not exist');
        }
  
        setIsLoading(false); // Set isLoading to false after data fetch completes
      }, (error) => {
        console.error('Error getting document:', error);
        setIsLoading(false);
      });

      return () => unsubscribe(); // Unsubscribe when component unmounts or userId changes
    }
  }, [userId]);



  const handleAccountSettings = async () => {
    if (userId) {
      const docRef = doc(dbFirestore, 'account', userId);
  
      try {
        
  
        await setDoc(docRef, {
          userType: userType || "",
          fullName: fullName || "",
          contactNumber: contactNumber || "",
          city: city || "",
          address: address || "",
	        
          
        }, { merge: true });
  
        setNotification('Account is successfully updated.');
        console.log('Account document updated successfully.');
      } catch (error) {
        setNotification('Error in updating Account.');
        console.error('Error updating account document:', error);
      }
    }
  };
  
  

  const handleChangePassword = async () => {
    if (!currentPassword || !newPassword) {
      console.log('Please enter both current and new passwords.');
      return;
    }
  
    const user = auth.currentUser;
    
    // Create a credential using the user's email and current password
    const credentials = EmailAuthProvider.credential(user.email, currentPassword);
  
    try {
      // Reauthenticate the user with their current password
      await reauthenticateWithCredential(user, credentials);
    
      // If reauthentication is successful, change the password
      await updatePassword(user, newPassword);
    
      // Password changed successfully
      setNotification('Password changed successfully.');
      console.log('Password changed successfully.');
    } catch (error) {
      // Handle reauthentication or password change error
      setNotification('Error in changing password.');
      console.error('Error changing password:', error);
    }
  };

  const handleChangeEmail = async () => {
    if (!newEmail || !newEmailPassword) {
      setNotification('Please enter both new email and current password.');
      return;
    }
  
    const auth = getAuth();
    const user = auth.currentUser;
  
    try {
      // Create a credential using the user's email and current password
      const credentials = EmailAuthProvider.credential(user.email, newEmailPassword);
  
      // Reauthenticate the user before sending verification email
      await reauthenticateWithCredential(user, credentials);
  
      // Send verification email to the new email address using verifyBeforeUpdateEmail
      await verifyBeforeUpdateEmail(user, newEmail);
      setNotification('A verification email has been sent to your new address. Please check your inbox and verify before proceeding.');
  
    } catch (error) {
      const errorMessage = error.message || 'An error occurred while processing your request.';
      let specificError = '';
  
      if (error.code === 'auth/requires-recent-login') {
        specificError = 'Your session has expired. Please sign in again and try changing your email.';
      } else if (error.code === 'auth/wrong-password') {
        specificError = 'The current password you entered is incorrect.';
      } else if (error.code === 'auth/invalid-email') {
        specificError = 'The new email address you entered is invalid.';
      } else if (error.code === 'auth/invalid-credential') {
        specificError = 'Invalid credential. Please check your email and password.';
      } // Add more specific errors as needed
  
      setNotification(specificError ? specificError : errorMessage);
      console.error('Error changing email address:', error);
    }
};

  
  const handleCloseAccount = async () => {
    if (!deletePassword || confirmationText !== 'CLOSE ACCOUNT') {
      setNotification('Invalid confirmation or missing current password. Account not closed.');
      console.log('Invalid confirmation or missing current password. Account not closed.');
      return;
    }
  
    try {
      const user = auth.currentUser;
  
      // Reauthenticate the user with their current password
      const credentials = EmailAuthProvider.credential(user.email, deletePassword);
      await reauthenticateWithCredential(user, credentials);
  
      // Delete user data from Firestore
      const docRef = doc(dbFirestore, 'account', user.uid);
      await deleteDoc(docRef);
  
      // Delete the user's account
      await deleteUser(user);
  
      // User account closed successfully
      navigate('/signup');
      setNotification('Account closed successfully.');
      console.log('Account closed successfully.');
    } catch (error) {
      // Handle errors, e.g., reauthentication error, database deletion error, etc.
      setNotification('Error closing account.');
      console.error('Error closing account:', error);
    }
  };
  
  
  const logoutStyle = {
    margin: '5px',
    padding: '6px 12px',
    fontSize: '15px',
    border: 'none',
    borderRadius: '5px',
    backgroundColor: '#4f4f4f',
    color: 'white',
    cursor: 'pointer'
  };
  
  const profileStyle = {
    margin: '5px',
    padding: '6px 12px',
    fontSize: '15px',
    border: 'none',
    borderRadius: '5px',
    backgroundColor: 'blue',
    color: 'white',
    cursor: 'pointer'
  };

  const picturebtnStyle = {
    padding: '10px 20px',
    margin: '0px',
    borderRadius: '0px',
    
    backgroundColor: '#fff',
    color: 'blue',
    cursor: 'pointer',
    outline: 'none',
  };

  const toggleSection = (section) => {
    if (section === 'userInfo') {
      setIsUserInfoOpen(!isUserInfoOpen);
    } else if (section === 'passwordEmailChange') {
      setIsPasswordEmailChangeOpen(!isPasswordEmailChangeOpen);
    } else if (section === 'accountClose') {
      setIsAccountCloseOpen(!isAccountCloseOpen);
    }
  };

  const [isUserInfoOpen, setIsUserInfoOpen] = useState(false);
  const [isPasswordEmailChangeOpen, setIsPasswordEmailChangeOpen] = useState(false);
  const [isAccountCloseOpen, setIsAccountCloseOpen] = useState(false);

  const renderIcon = (section) => {
    if (section === 'userInfo') {
      return isUserInfoOpen ? '' : '' /* or '-' : '+'*/;
    } else if (section === 'passwordEmailChange') {
      return isPasswordEmailChangeOpen ? '' : '' /* or '-' : '+'*/;
    } else if (section === 'accountClose') {
      return isAccountCloseOpen ? '' : '' /* or '-' : '+'*/;
    }
  };
  
  

  return (
    <div>

      <div className="parent-container">
        {isLoading && <LoadingSpinner />}
        {!isLoading && (
          <>
            <div className="settings-container">
          
        <div className="column-three-account-settings">
         
          <Link to={`/profile/${userId}`} >
            <button style={ profileStyle }>Program</button>
          </Link>
          <h4>
            <button style={ logoutStyle } onClick={handleLogout}>Logout</button>
          </h4>
        </div>
              {notification && <Notification message={notification} duration={2000} onClose={() => setNotification(null)} />}
              
              {/* Verification */}
              <div className="verification-section">
                {isEmailVerifiedState ? (
                  <p>
                    <Icon icon="octicon:verified-16" color="green" width="20" height="20" /> Account is verified
                  </p>
                ) : (
                  <p>
                    <Icon icon="octicon:unverified-16" color="grey" width="20" height="20"/> Account is not verified
                  </p>
                )}
                {!isEmailVerifiedState && (
                  <button style={picturebtnStyle} onClick={handleSendEmailVerification}>Request Email to verify Account.</button>
                )}
              </div>
              
              {/* User Info */}
              <div className="card">
                <div onClick={() => toggleSection('userInfo')} className="card-title">
                  <Icon icon="solar:user-id-bold" color="black" width="40" height="40" style={{ marginRight: '10px' }} />
                  User Info {renderIcon('userInfo')} 
                </div>
                {isUserInfoOpen && (
                  <>
                 
{/*
<div className="column-one-edit-profile-picture-settings">
  
  <label htmlFor="profile-picture-input">
<img
src={
  markedForDeletionProfilePicture
    ? `${process.env.PUBLIC_URL}/profile-picture.png`
    : profilePictureURL
    ? `${profilePictureURL}?${new Date().getTime()}`
    : process.env.PUBLIC_URL + '/fallback-profile-picture.png'
}
alt="Profile Picture"
className="profile-picture-settings"
/>
</label>


<input
type="file"
accept="image/*"
id="profile-picture-input"
onChange={handleProfilePictureChange}
style={{ display: 'none' }}
/>
{profilePicture && (
<div>
<p>{fileNameProfilePicture}</p>
</div>

)}
<button style={picturebtnStyle} onClick={handleSaveProfile}>Save Picture</button>
      <button style={picturebtnStyle} onClick={() => { handleSaveProfile(); handleCancelUploadProfilePicture(); }}>Remove Picture</button>
</div> */}

{user && (
          
          <p>{user.email}</p>
        
      )}
                    <ul>
                    <li>
                    <select
                  value={userType}
                  onChange={(e) => setUserType(e.target.value)}
                  required
                >
                  <option value="">Select User type</option>
                  <option value="Individual">Individual</option>
                  <option value="Business">Business</option>
                  <option value="Individual and Business">Individual and Business</option>
                </select>
                        
                      </li>
                      <li>
                        <input
                          type="text"
                          placeholder="Full Name"
                          value={fullName}
                          onChange={(e) => setFullName(e.target.value)}
                        />
                      </li>
                      
                      <li>
                        <input
                          type="text"
                          placeholder="Contact Number"
                          value={contactNumber}
                          onChange={(e) => setContactNumber(e.target.value)}
                        />
                      </li>
                      <li>
                        <input
                          type="text"
                          placeholder="City"
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                        />
                      </li>
                      <li>
                      <textarea
  placeholder="Physical Address"
  value={address}
  onChange={(e) => setAddress(e.target.value)}
  rows={4}
  cols={40}
  style={{ width: '80%', height: '100px' }}
/>

                      </li>
                    </ul>
                    <button style={picturebtnStyle} onClick={handleAccountSettings}>Save User Account Information</button>
                  </>
                )}
              </div>

              {/* Change Password */}
              <div className="card">
                <div onClick={() => toggleSection('passwordEmailChange')} className="card-title">
                  <Icon icon="dashicons:update-alt" color="black" width="35" height="25" style={{ marginRight: '5px' }} />
                  Password/ Email{renderIcon('passwordEmailChange')}
                </div>
                {isPasswordEmailChangeOpen && (
                  <>
                    <div className="password-change-section">
                      <ul>
                        <li>
                          <input
                            type="password"
                            placeholder="Current Password"
                            value={currentPassword}
                            onChange={(e) => setCurrentPassword(e.target.value)}
                          />
                        </li>
                        <li>
                          <input
                            type="password"
                            placeholder="New Password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                          />
                        </li>
                        <li>
                          <input
                            type="password"
                            placeholder="Confirm New Password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                          />
                        </li>
                      </ul>
                      <p><button style={picturebtnStyle} onClick={handleChangePassword}>Change Password</button></p>
                    </div>
                    <div className="email-change-section">
                      <ul>
                        <li>
                          <input
                            type="email"
                            placeholder="New Email Address"
                            value={newEmail}
                            onChange={(e) => setNewEmail(e.target.value)}
                          />
                        </li>
                        <li>
                          <input
                            type="password"
                            placeholder="Current Password"
                            value={newEmailPassword}
                            onChange={(e) => setNewEmailPassword(e.target.value)}
                          />
                        </li>
                      </ul>
                      <p><button style={picturebtnStyle} onClick={handleChangeEmail}>Change Email</button></p>
                    </div>
                  </>
                )}
              </div>

              {/* Close Account */}
              <div className="card">
                <div onClick={() => toggleSection('accountClose')} className="card-title">
                  <Icon icon="tabler:user-off" color="black" width="40" height="40" style={{ marginRight: '10px' }} />
                  Close Account {renderIcon('accountClose')}
                </div>
                {isAccountCloseOpen && (
                  <>
                    <p>
                      <strong>Please enter your current password</strong>
                      <input
                        type="password"
                        placeholder="Current Password"
                        value={deletePassword}
                        onChange={(e) => setDeletePassword(e.target.value)}
                      />
                    </p>
                    <p>
                      <strong>Please enter "CLOSE ACCOUNT" to confirm:</strong>
                    </p>
                    <input
                      type="text"
                      placeholder="Confirmation Text"
                      value={confirmationText}
                      onChange={(e) => setConfirmationText(e.target.value)}
                    />
                    <p>
                      <button style={picturebtnStyle} onClick={handleCloseAccount}>Close Account</button>
                    </p>
                  </>
                )}
              </div>
              
            </div>
          </>
        )}
        
      </div>
      
    </div>
  );
};

export default AccountSettings;