import React, { useState, useEffect } from 'react';
import { collection, query, getDocs, getDoc, onSnapshot, addDoc, deleteDoc, doc, setDoc, doc as firestoreDoc } from 'firebase/firestore';
import { auth, dbFirestore, signOut } from '../../firebase';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Notification from '../../Notification';
import { useNavigate, Link } from "react-router-dom";
import "./UserAdmin.css";
import LoadingSpinner from '../../LoadingSpinner';
import { useParams } from 'react-router-dom';
import { Icon } from '@iconify/react';

const profileStyle = {
  backgroundColor: '#ffffff',
};



const getFormattedDate = () => {
  const options = {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  };
  const currentDate = new Date().toLocaleDateString(undefined, options);
  return currentDate;
};

function UserAdmin() {
  const { userId } = useParams();
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [notification, setNotification] = useState(null);
  const [domains, setDomains] = useState([]);
  
  const [showEmptyRow, setShowEmptyRow] = useState(false);
  const [payCycle, setPayCycle] = useState('');
  const [showScreenChanges, setShowScreenChanges] = useState(false);
 
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(3);

  const [expandedRows, setExpandedRows] = useState({});
 
  const navigate = useNavigate();

  const requirementOptions = ["Support", "Consultation", "Introduction", "Other"];
  const [editedDomain, setEditedDomain] = useState({ requirement: '', captureType: '' });

  useEffect(() => {
    // Listen for authentication state changes
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        // User is signed in, update the user state
        setUser(user);
      } else {
        // User is signed out, redirect to sign-in page
        navigate('/sign-in'); // Replace '/signin' with your actual sign-in route
      }
    });

    // Clean up the subscription to avoid memory leaks
    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    if (user) {
      fetchDomains();
    }
  }, [user]);

  useEffect(() => {
    if (userId) {
      const unsubscribe = onSnapshot(doc(dbFirestore, 'account', userId), (docSnapshot) => {
        if (docSnapshot.exists()) {
          const userData = docSnapshot.data();
          setPayCycle(userData.payCycle || '');
        } else {
          console.log('User not found');
        }
      });

      return () => unsubscribe();
    }
  }, [userId]);

  const fetchDomains = async () => {
    try {
      const userDomainsRef = collection(dbFirestore, 'users', userId, 'domains');
      const q = query(userDomainsRef);
      const querySnapshot = await getDocs(q);
      const domainsData = querySnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          orderValue: data.orderValue || data.value,
        };
      });
      setDomains(domainsData);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching domains:', error);
      setIsLoading(false);
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/sign-in');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const addDomain = () => {
    setEditedDomain({
      domainName: '',
      domainEmail: '',
      phone: '',
      requirement: '',
      description: '',
      value: '',
      orderPayment: 'No',
      capture: '',
      orderDate: getFormattedDate(),
      ownerId: userId,
    });
    setShowEmptyRow(true);
  };


  const removeDomain = async (domainId) => {
    if (userId) {
      const userDomainDocRef = doc(dbFirestore, 'users', userId, 'domains', domainId);
      try {
        await deleteDoc(userDomainDocRef);
        // Update earnings after removing domain
        await updateEarnings();
        fetchDomains();
        setNotification('Domain was successfully removed');
      } catch (error) {
        setNotification('Error in removing domain');
        console.error('Error removing domain:', error);
      }
    }
  };
  
  const editDomain = (domainId) => {
    const editedDomain = domains.find(domain => domain.id === domainId);
    setEditedDomain(editedDomain);
    setShowEmptyRow(true);
  };

  const saveChanges = async () => {
    if (userId && editedDomain) {
      try {
        if (editedDomain.id) {
          const userDomainDocRef = doc(dbFirestore, 'users', userId, 'domains', editedDomain.id);
          await setDoc(userDomainDocRef, editedDomain, { merge: true });
        } else {
          const userDomainsRef = collection(dbFirestore, 'users', userId, 'domains');
          // Update orderValue before adding to Firestore
          const orderValue = parseFloat(editedDomain.value); // Parse the value to float
          const domainToAdd = { ...editedDomain, orderValue: orderValue };
          await addDoc(userDomainsRef, domainToAdd);
        }
        await fetchDomains(); // Wait for domains to be fetched
        
        const userDocRef = doc(dbFirestore, 'account', userId);
        await updateEarnings(userDocRef); // Update earnings after changes are saved
        setNotification('Changes were successfully done.');
        setShowEmptyRow(false);
        setEditedDomain({});
      } catch (error) {
        console.error('Error saving changes:', error);
      }
    }
  };

  
  const updateEarnings = async (userDocRef) => {
  try {
    // Update total earnings and count in Firestore
    await setDoc(userDocRef, {
      Earnings: {
        totalEarnings: totalEarnings,
        totalYesCount: totalYesCount,
        currentWeekEarnings: currentWeekEarnings,
        previousWeekEarnings: previousWeekEarnings,
        currentWeekYesCount: currentWeekYesCount,
        previousWeekYesCount: previousWeekYesCount,
        currentMonthEarnings: currentMonthEarnings,
        previousMonthEarnings: previousMonthEarnings,
        currentMonthYesCount: currentMonthYesCount,
        previousMonthYesCount: previousMonthYesCount,
        currentYearEarnings: currentYearEarnings,
        previousYearEarnings: previousYearEarnings,
        currentYearYesCount: currentYearYesCount,
        previousYearYesCount: previousYearYesCount,
      }
    }, { merge: true });

    console.log("Earnings updated successfully in Firestore.");
  } catch (error) {
    console.error("Error updating earnings in Firestore:", error);
  }
};

  

        // Calculate earnings and counts for different periods
        const totalEarnings = domains.reduce((total, domain) => {
          if (domain.orderPayment === 'Yes') {
            return total + parseFloat(domain.orderValue);
          } else {
            return total;
          }
        }, 0);
    
        const totalYesCount = domains.reduce((total, domain) => {
          if (domain.orderPayment === 'Yes') {
            return total + 1;
          } else {
            return total;
          }
        }, 0);
  
  // Calculate total earnings for the current week
  const calculateWeeklyEarnings = (startDate, endDate) => {
    return domains.reduce((total, domain) => {
      const orderDate = new Date(domain.orderDate);
      if (orderDate >= startDate && orderDate <= endDate && domain.orderPayment === 'Yes') {
        return total + parseFloat(domain.orderValue);
      } else {
        return total;
      }
    }, 0);
  };
  
  // Calculate total count of "Yes" for the current week
  const calculateWeeklyYesCount = (startDate, endDate) => {
    return domains.reduce((total, domain) => {
      const orderDate = new Date(domain.orderDate);
      if (orderDate >= startDate && orderDate <= endDate && domain.orderPayment === 'Yes') {
        return total + 1;
      } else {
        return total;
      }
    }, 0);
  };
  
  // Function to calculate total earnings for a specific month
  const calculateMonthlyEarnings = (year, month) => {
    return domains.reduce((total, domain) => {
      const orderDate = new Date(domain.orderDate);
      if (orderDate.getFullYear() === year && orderDate.getMonth() === month && domain.orderPayment === 'Yes') {
        return total + parseFloat(domain.orderValue);
      } else {
        return total;
      }
    }, 0);
  };
  
  // Function to calculate total count of "Yes" for a specific month
  const calculateMonthlyYesCount = (year, month) => {
    return domains.reduce((total, domain) => {
      const orderDate = new Date(domain.orderDate);
      if (orderDate.getFullYear() === year && orderDate.getMonth() === month && domain.orderPayment === 'Yes') {
        return total + 1;
      } else {
        return total;
      }
    }, 0);
  };
  
  // Function to calculate total earnings for a specific year
  const calculateYearlyEarnings = (year) => {
    return domains.reduce((total, domain) => {
      const orderDate = new Date(domain.orderDate);
      if (orderDate.getFullYear() === year && domain.orderPayment === 'Yes') {
        return total + parseFloat(domain.orderValue);
      } else {
        return total;
      }
    }, 0);
  };
  
  // Function to calculate total count of "Yes" for a specific year
  const calculateYearlyYesCount = (year) => {
    return domains.reduce((total, domain) => {
      const orderDate = new Date(domain.orderDate);
      if (orderDate.getFullYear() === year && domain.orderPayment === 'Yes') {
        return total + 1;
      } else {
        return total;
      }
    }, 0);
  };
  
  // Get current week dates
  const getCurrentWeekDates = () => {
    const currentDate = new Date();
    const currentDay = currentDate.getDay(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday
    const firstDayOfWeek = new Date(currentDate); // Clone current date
    firstDayOfWeek.setDate(currentDate.getDate() - currentDay); // Set date to Monday of the current week
  
    // Set time to 00:00:00 for first day of the week
    firstDayOfWeek.setHours(0, 0, 0, 0);
  
    const lastDayOfWeek = new Date(firstDayOfWeek);
    lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6); // Set date to Sunday of the current week
  
    // Set time to 23:59:59 for last day of the week
    lastDayOfWeek.setHours(23, 59, 59, 999);
  
    return { start: firstDayOfWeek, end: lastDayOfWeek };
  };
  
  // Get previous week dates
  const getPreviousWeekDates = () => {
    const { start: currentWeekStart } = getCurrentWeekDates();
    const previousWeekStart = new Date(currentWeekStart);
    previousWeekStart.setDate(currentWeekStart.getDate() - 7); // Set date to Monday of the previous week
  
    const previousWeekEnd = new Date(previousWeekStart);
    previousWeekEnd.setDate(previousWeekStart.getDate() + 6); // Set date to Sunday of the previous week
  
    return { start: previousWeekStart, end: previousWeekEnd };
  };
  
  // Get current month dates
  const getCurrentMonthDates = () => {
    const currentDate = new Date();
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
  
    // Set time to 00:00:00 for first day of the month
    firstDayOfMonth.setHours(0, 0, 0, 0);
  
    // Set time to 23:59:59 for last day of the month
    lastDayOfMonth.setHours(23, 59, 59, 999);
  
    return { start: firstDayOfMonth, end: lastDayOfMonth };
  };
  
  // Get previous month dates
  const getPreviousMonthDates = () => {
    const currentDate = new Date();
    const firstDayOfPreviousMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
    const lastDayOfPreviousMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
  
    // Set time to 00:00:00 for first day of the month
    firstDayOfPreviousMonth.setHours(0, 0, 0, 0);
  
    // Set time to 23:59:59 for last day of the month
    lastDayOfPreviousMonth.setHours(23, 59, 59, 999);
  
    return { start: firstDayOfPreviousMonth, end: lastDayOfPreviousMonth };
  };
  
  // Get current year dates
  const getCurrentYearDates = () => {
    const currentDate = new Date();
    const firstDayOfYear = new Date(currentDate.getFullYear(), 0, 1);
    const lastDayOfYear = new Date(currentDate.getFullYear(), 11, 31);
  
    // Set time to 00:00:00 for first day of the year
    firstDayOfYear.setHours(0, 0, 0, 0);
  
    // Set time to 23:59:59 for last day of the year
    lastDayOfYear.setHours(23, 59, 59, 999);
  
    return { start: firstDayOfYear, end: lastDayOfYear };
  };
  
  // Get previous year dates
  const getPreviousYearDates = () => {
    const currentDate = new Date();
    const firstDayOfPreviousYear = new Date(currentDate.getFullYear() - 1, 0, 1);
    const lastDayOfPreviousYear = new Date(currentDate.getFullYear() - 1, 11, 31);
  
    // Set time to 00:00:00 for first day of the year
    firstDayOfPreviousYear.setHours(0, 0, 0, 0);
  
    // Set time to 23:59:59 for last day of the year
    lastDayOfPreviousYear.setHours(23, 59, 59, 999);
  
    return { start: firstDayOfPreviousYear, end: lastDayOfPreviousYear };
  };
  
  // Calculate current week's start and end dates
  const currentWeekDates = getCurrentWeekDates();
  
  // Calculate previous week's start and end dates
  const previousWeekDates = getPreviousWeekDates();
  
  // Calculate current month's start and end dates
  const currentMonthDates = getCurrentMonthDates();
  
  // Calculate previous month's start and end dates
  const previousMonthDates = getPreviousMonthDates();
  
  // Calculate current year's start and end dates
  const currentYearDates = getCurrentYearDates();
  
  // Calculate previous year's start and end dates
  const previousYearDates = getPreviousYearDates();
  
  // Calculate total earnings for current week
  const currentWeekEarnings = calculateWeeklyEarnings(currentWeekDates.start, currentWeekDates.end);
  
  // Calculate total earnings for previous week
  const previousWeekEarnings = calculateWeeklyEarnings(previousWeekDates.start, previousWeekDates.end);
  
  // Calculate total "Yes" count for current week
  const currentWeekYesCount = calculateWeeklyYesCount(currentWeekDates.start, currentWeekDates.end);
  
  // Calculate total "Yes" count for previous week
  const previousWeekYesCount = calculateWeeklyYesCount(previousWeekDates.start, previousWeekDates.end);
  
  // Calculate total earnings for current month
  const currentMonthEarnings = calculateMonthlyEarnings(currentMonthDates.start.getFullYear(), currentMonthDates.start.getMonth());
  
  // Calculate total earnings for previous month
  const previousMonthEarnings = calculateMonthlyEarnings(previousMonthDates.start.getFullYear(), previousMonthDates.start.getMonth());
  
  // Calculate total "Yes" count for current month
  const currentMonthYesCount = calculateMonthlyYesCount(currentMonthDates.start.getFullYear(), currentMonthDates.start.getMonth());
  
  // Calculate total "Yes" count for previous month
  const previousMonthYesCount = calculateMonthlyYesCount(previousMonthDates.start.getFullYear(), previousMonthDates.start.getMonth());
  
  // Calculate total earnings for current year
  const currentYearEarnings = calculateYearlyEarnings(currentYearDates.start.getFullYear());
  
  // Calculate total earnings for previous year
  const previousYearEarnings = calculateYearlyEarnings(previousYearDates.start.getFullYear());
  
  // Calculate total "Yes" count for current year
  const currentYearYesCount = calculateYearlyYesCount(currentYearDates.start.getFullYear());
  
  // Calculate total "Yes" count for previous year
  const previousYearYesCount = calculateYearlyYesCount(previousYearDates.start.getFullYear());
  
  

  const cancelChanges = () => {
    setShowEmptyRow(false);
    setEditedDomain(null);
  };


  const handleInputChange = (event, field) => {
    const value = event.target.value;
    if (field === 'orderPayment') {
      const updatedOrderPayment = editedDomain.orderPayment === 'No' ? 'Yes' : 'No';
      setEditedDomain({ ...editedDomain, orderPayment: updatedOrderPayment });
    } else if (field === 'value') {
      // Update orderValue based on the new value input
      const orderValue = parseFloat(value); // Parse the input value to float
      setEditedDomain({ ...editedDomain, value: value, orderValue: orderValue });
    } else {
      setEditedDomain({ ...editedDomain, [field]: value });
    }
  };
  
  

  const handleOrderPaymentToggle = async () => {
    // Toggle between 'Yes' and 'No' based on the current state
    const updatedOrderPayment = editedDomain.orderPayment === 'Yes' ? 'No' : 'Yes';
    setEditedDomain({ ...editedDomain, orderPayment: updatedOrderPayment });
  
    // Check if the updated orderPayment is 'Yes', then update earnings
    if (updatedOrderPayment === 'Yes') {
      try {
        await updateEarnings();
      } catch (error) {
        console.error('Error updating earnings:', error);
      }
    }
  };
  




useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setShowScreenChanges(true);
      } else {
        setShowScreenChanges(false);
      }
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Initial check for window width
    handleResize();

    // Cleanup function to remove event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  

   // Sort the domains by timestamp in descending order
   const sortedDomains = domains.slice().sort((a, b) => {
    // Assuming each domain entry has a timestamp field called 'timestamp'
    return new Date(b.timestamp) - new Date(a.timestamp);
  });
    const isSmallScreen = window.innerWidth <= 768;

    const ordersToDisplay = sortedDomains.slice(startIndex, endIndex);

    
    const thStyle = {
      backgroundColor: '#f0f0f0',
      padding: '8px',
      textAlign: 'left',
      borderBottom: '1px solid #ddd',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: isSmallScreen ? '150px' : '200px',
    };

    const tdStyle = {
      padding: '8px',
      borderBottom: '1px solid #ddd',
      backgroundColor: '#f0f0f0',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: isSmallScreen ? '100px' : '200px',
    };

    const TooltipbtnStyle = {
      backgroundColor: '#eeeeee',
      color: '#000000',
      padding: '2px 4px', // Adjust padding as needed for your content
      border: 'none',
      borderRadius: '5px', // Border radius for rounded corners
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center', // Center content vertically
      justifyContent: 'center', // Center content horizontally
     
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', // Optional box shadow for a raised effect
    };

    const toggleRowExpansion = (domainId) => {
      setExpandedRows((prevExpandedRows) => ({
        ...prevExpandedRows,
        [domainId]: !prevExpandedRows[domainId],
      }));
    };

    const tableStyle = {
      borderCollapse: 'collapse',
      width: '100%',
      textAlign: 'left',
  
    };

     // Media query to adjust styles for smaller screens
     if (window.innerWidth <= 768) {
      tdStyle.maxWidth = '100px'; // Adjust maxWidth for smaller screens
      thStyle.maxWidth = '150px'; // Adjust maxWidth for smaller screens
    }

    const expandbtnStyle = {
      margin: '5px',
      padding: '6px 12px',
      fontSize: '12px',
      border: 'none',
      borderRadius: '5px',
      backgroundColor: 'blue',
      color: 'white',
      cursor: 'pointer'
    };



    const NxtNavbuttonStyle = {

      position: 'absolute',
    
      right: '10px',
     
      
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'right', 
     
        margin: '0 5px', // Margin between buttons
        padding: '8px', // Padding inside buttons
        cursor: 'pointer', // Cursor style
        backgroundColor: 'transparent',
      };
    
      const PreNavbuttonStyle = {
    
        position: 'absolute',
    
      right: '40px',
     
      
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'right', 
     
        margin: '0 5px', // Margin between buttons
        padding: '8px', // Padding inside buttons
        cursor: 'pointer', // Cursor style
        backgroundColor: 'transparent',
      };
      
      const disabledNxtNavButtonStyle = {
        ...NxtNavbuttonStyle,
    
        cursor: 'not-allowed', // Change cursor to not-allowed for disabled buttons
        color: 'grey', // Set text color to grey for disabled buttons
    
      alignItems: 'center',
      justifyContent: 'center',
      };
    
      const disabledPreNavButtonStyle = {
        ...PreNavbuttonStyle,
    
        cursor: 'not-allowed', // Change cursor to not-allowed for disabled buttons
        color: 'grey', // Set text color to grey for disabled buttons
    
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'transparent',
      };
      
      
      const NaviconStyle = {
        marginRight: '5px', // Margin between icon and text
      };
    
      
      const handlePreviousPage = () => {
        const newStartIndex = Math.max(startIndex - 3, 0);
        setStartIndex(newStartIndex);
        setEndIndex(newStartIndex + 3);
      };
  
      const handleNextPage = () => {
        const newStartIndex = endIndex;
        const newEndIndex = Math.min(endIndex + 3, domains.length);
        setStartIndex(newStartIndex);
        setEndIndex(newEndIndex);
      };



  return (
    <div style={profileStyle}>
      {notification && <Notification message={notification} duration={2000} onClose={() => setNotification(null)} />}
      <div className="header-manage-domains">
        <div className="column-three-manage-domains">
          <Link to="/marketplace" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'black' }}>
            <button style={{ marginRight: '15px', padding: '2px 4px', border: '1px solid black', borderRadius: '5px', background: 'transparent', cursor: 'pointer' }}>Marketplace</button>
          </Link>
          <Link to="/dashboard" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'black' }}>
            <button style={{ marginRight: '15px', padding: '2px 4px', border: '1px solid black', borderRadius: '5px', background: 'transparent', cursor: 'pointer' }}>Dashboard</button>
          </Link>
          <h4><button style={{ margin: '5px', padding: '6px 12px', fontSize: '12px', border: 'none', borderRadius: '5px', backgroundColor: '#4f4f4f', color: 'white', cursor: 'pointer' }} onClick={handleLogout}>Logout</button></h4>
        </div>
      </div>
      <div>
        {isLoading && <LoadingSpinner />}
        {!isLoading && (
          <>
            <div>
            <h2>
              <button
  style={startIndex <= 0 ? disabledPreNavButtonStyle : PreNavbuttonStyle}
  onClick={handlePreviousPage}
  disabled={startIndex <= 0}
>
  <Icon icon="carbon:previous-filled" color={startIndex <= 0 ? 'grey' : 'black'} width="24" height="24" style={NaviconStyle} />

</button>

<button
  style={endIndex >= domains.length ? disabledNxtNavButtonStyle : NxtNavbuttonStyle}
  onClick={handleNextPage}
  disabled={endIndex >= domains.length}
>
  <Icon icon="carbon:next-filled" color={endIndex >= domains.length ? 'grey' : 'black'} width="24" height="24" style={NaviconStyle} />
</button>

              </h2>
              <p>Total Earnings: {totalEarnings}</p>
              <p>Total Yes Count: {totalYesCount}</p>
              <p>Previous Week Earnings: {previousWeekEarnings}</p>
              <p>Current Week Earnings: {currentWeekEarnings}</p>
              
              
            </div>
            Pay Cycle: {payCycle}
            <div>
  
              {/* Table for input fields */}
              {!showEmptyRow && (
  <table style={tableStyle}>
    <thead>
      <tr>
        <th style={thStyle}>Activity</th>
       
        {isSmallScreen && <th style={thStyle}></th>}
        {!isSmallScreen && (
          <>
            <th style={thStyle}>Type</th>
            <th style={thStyle}>Description</th>
            <th style={thStyle}>Method</th>
            <th style={thStyle}>Date captured</th>
            <th style={thStyle}>Actions</th>
          </>
        )}
      </tr>
    </thead>
    <tbody>
      {ordersToDisplay.map((domain) => {
        const rows = [
          <tr key={domain.id}>
            <td style={tdStyle}>{domain.domainName}</td>
            
            {isSmallScreen && (
              <td style={tdStyle}>
                <button style={expandbtnStyle} className="expand-button" onClick={() => toggleRowExpansion(domain.id)}>
                  {expandedRows[domain.id] ? '-' : '+'}
                </button>
              </td>
            )}
            {!isSmallScreen && (
              <>
                <td style={tdStyle}>{domain.requirement}</td>
                <td style={tdStyle}>{domain.description}</td>
                <td style={tdStyle}>{domain.captureType}</td>
                <td style={tdStyle}>{domain.orderDate}</td>
                <td style={tdStyle}>
                  <button onClick={() => editDomain(domain.id)}>Edit</button>
                  <button onClick={() => removeDomain(domain.id)}>Remove</button>
                </td>
              </>
            )}
          </tr>
        ];

        if (isSmallScreen && expandedRows[domain.id]) {
          rows.push(
            <tr key={`${domain.id}-expanded`}>
              <td colSpan={isSmallScreen ? '3' : '10'}>
                <div>
                  <p style={{ color: 'grey', textAlign: 'center', lineHeight: '1.5' }}>
                    <strong>{domain.domainName}</strong>
                  </p>
                  <p><strong>Type:</strong> {domain.requirement}</p>
                  <p><strong>Description:</strong> {domain.description ? domain.description : 'No description is provided'}</p>
                  <p><strong>Method:</strong> {domain.captureType}</p>
                  <p><strong>Date Captured:</strong> {domain.orderDate}</p>
                  <p><button onClick={() => editDomain(domain.id)}>Edit</button>
                  <button onClick={() => removeDomain(domain.id)}>Remove</button></p>
                </div>
              </td>
            </tr>
          );
        }

        return rows;
      })}
    </tbody>
  </table>
)}

              {/* Table for input fields */}
              {showEmptyRow && (
                <table>
                  <tbody>
                      <tr className="edit-input-row">
                      <td><input type="text" placeholder="Focus Area" value={editedDomain.domainName} onChange={(e) => handleInputChange(e, 'domainName')} /></td>
                      
                      <td>
                      <select value={editedDomain.requirement} onChange={(e) => handleInputChange(e, 'requirement')}>
        <option value="">Select Requirement</option>
        {requirementOptions.map(option => (
          <option key={option} value={option}>{option}</option>
        ))}
      </select>
                      </td>
                      <td><textarea placeholder="Description" value={editedDomain.description} onChange={(e) => handleInputChange(e, 'description')} /></td>
                      

                      <td><select value={editedDomain.captureType} onChange={(e) => handleInputChange(e, 'captureType')}>
        <option value="">Select Type</option>
        <option value="Chat">Chat</option>
        <option value="Email">Email</option>
        <option value="Call">Call</option>
     
      </select></td>
                      <td>{editedDomain.orderDate}</td>
                      <td>
                        <button onClick={saveChanges}>Save</button>
                        <button onClick={cancelChanges}>Cancel</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
  
            {!showEmptyRow && <button onClick={addDomain}>Add Domain</button>}<button onClick={saveChanges}>ALWAYS Update</button>
          </>
        )}
      </div>
    </div>
  );
}  

export default UserAdmin;
