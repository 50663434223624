import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import SignIn from "./components/authentication/SignIn";
import SignUp from "./components/authentication/SignUp";
import ResetPassword from "./components/authentication/ResetPassword";
import AdminAll from './components/administration/AdminAll';
import UserAdmin from './components/administration/UserAdmin';
import UserInfo from './components/administration/UserInfo';
import MonthlyPay from './components/administration/MonthlyPay';
import WeeklyPay from './components/administration/WeeklyPay';
import ManageOrders from './components/administration/ManageOrders';
import Statistics from './components/administration/Statistics';
import Orders from "./components/public/Orders";
import HowToEarn from "./components/HowToEarn";
import Website from "./components/Website";
import Profile from "./components/administration/Profile";
import UserProfilePopup from "./components/administration/UserProfilePopup";
import AccountPage from "./components/AccountPage";
import AccountSettings from "./components/AccountSettings";
import Income from "./components/Income";
import UserStatistics from "./components/UserStatistics";
import Payments from "./components/Payments";
import EditProfile from './components/EditProfile';


function App() {



  return (
    
      <Router>
     
        <Routes>

          // Authentication Pages
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          
          // Admin Pages
          <Route path="/user-admin/:userId" element={<UserAdmin />} />
          <Route path="/user-info" element={<UserInfo />} />        
          <Route path="/weekly-pay" element={<WeeklyPay />} />
          <Route path="/monthly-pay" element={<MonthlyPay />} />
          <Route path="/manage-orders" element={<ManageOrders />} />
          <Route path="/statistics" element={<Statistics />} />
          
         
          //User Pages
          <Route path="/profile/:userId" element={<Profile />} />
          <Route path="/user-statistics/:userId" element={<UserStatistics />} />
          <Route path="/account-settings/:userId" element={<AccountSettings />} />
          <Route path="/how-to-earn/:userId" element={<HowToEarn />} />
          <Route path="/website/:userId" element={<Website />} />

          <Route path="/income" element={<Income />} />
       
          <Route path="/*" element={<SignIn />} /> {/* Default route */}
          <Route path="*" element={<NotFound />} />
        </Routes>
       
      </Router>
    
  );
}



function NotFound() {
  return (
    <div>
      <h2>404 - Not Found</h2>
      <p>Sorry, the page you are looking for does not exist.</p>
    </div>
  );
}

export default App;
