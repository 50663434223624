import React, { useState, useEffect } from 'react';
import { collection, query, getDocs, doc, getDoc, onSnapshot, Timestamp } from 'firebase/firestore';
import { auth, dbFirestore, sendEmailVerification, signOut, storage } from '../../firebase';
import { getDownloadURL, ref } from 'firebase/storage';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';
import Tooltip from '../Tooltip';
import { Icon } from '@iconify/react';
import LoadingSpinner from '../../LoadingSpinner';
import "./Profile.css";


const Profile = ({ onClose }) => {
  const { userId } = useParams();
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const [userDomains, setUserDomains] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [expandedRows, setExpandedRows] = useState({});

  const [fullName, setFullName] = useState('');
  const [createdAt, setCreatedAt] = useState('');
  const [uniqueCode, setUniqueCode] = useState('');
  const [isCopied, setIsCopied] = useState(false);
  const [payCycle, setpayCycle] = useState('');
  

  const [profilePictureURL, setProfilePictureURL] = useState('');
  const [coverPhotoURL, setCoverPhotoURL] = useState('');

  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(3);

  const [currentMonthYear, setCurrentMonthYear] = useState(
    new Date().toLocaleDateString('default', { month: 'long', year: 'numeric' })
);

  const [weekDay, setWeekDay] = useState('');
  
  const [day, setDay] = useState('');
  const [topic, setTopic] = useState('');
  const [view, setView] = useState('schedule');

  const supportHours = isSupportHours();

  const timestamp = Date.now(); // Get current timestamp
  const weekNumber = getWeekNumber(timestamp);
  console.log(weekNumber);


  


  useEffect(() => {
    // Listen for authentication state changes
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        // User is signed in, update the user state
        setUser(user);
      } else {
        // User is signed out, redirect to sign-in page
        navigate('/sign-in'); // Replace '/signin' with your actual sign-in route
      }
    });

    // Clean up the subscription to avoid memory leaks
    return () => unsubscribe();
  }, [navigate]);

   // Function to handle user logout
   const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/sign-in'); // Redirect to the login page after logout
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  // Define topics for each day
const topics = {
    Monday: 'Information',
    Tuesday: 'Website development',
    Wednesday: 'Clients Management',
    Thursday: 'Marketing channels',
    Friday: 'Other systems',
    Saturday: 'Unavailable',
    Sunday: 'Unavailable',
  };

  
  // Function to get the current day of the week
  const getDayOfWeek = () => {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const currentDayIndex = new Date().getDay();
    return days[currentDayIndex];
  };

  useEffect(() => {
    const today = getDayOfWeek();
    setDay(today);
    setTopic(topics[today]);
  }, []);



  useEffect(() => {
    const fetchProfilePictureURL = async () => {
      try {
        const docRef = doc(dbFirestore, 'users', userId);
        const docSnapshot = await getDoc(docRef);
        if (docSnapshot.exists()) {
          const userData = docSnapshot.data();
          const profilePictureRef = ref(storage, `profilePictures/${userId}`);
          const url = await getDownloadURL(profilePictureRef);
          setProfilePictureURL(url);
        }
      } catch (error) {
        console.error('Error fetching profile picture URL:', error);
      }
    };

    fetchProfilePictureURL();
  }, [userId]);
  
  

  useEffect(() => {
    const docRef = doc(dbFirestore, 'account', userId);

    getDoc(docRef)
      .then((docSnapshot) => {
        if (docSnapshot.exists()) {
          const userData = docSnapshot.data();
          setFullName(userData.fullName || '');
          setCreatedAt(userData.createdAt || '');
          setUniqueCode(userData.uniqueCode || '');
          setpayCycle(userData.payCycle || '');
          
          setProfilePictureURL(userData.profilePictureURL || '');
          setCoverPhotoURL(userData.coverPhotoURL || '');


          // Convert Firestore Timestamp to JavaScript Date object
        const createdAtTimestamp = userData.createdAt;
        const createdAtDate = createdAtTimestamp.toDate();

        // Format the date as "Month Year"
        const monthYear = createdAtDate.toLocaleString('default', { month: 'long' }) + ' ' + createdAtDate.getFullYear();
        setCreatedAt(monthYear);
          
        
      }
        
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error getting document:', error);
        setIsLoading(false);
      });
  }, [userId]);

  // Function to extract month and year from createdAt field
  const extractMonthYear = (createdAt) => {
    if (!createdAt) return '';

    const date = new Date(createdAt);
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    return `${month} ${year}`;
  };

  const memberSince = extractMonthYear(createdAt);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(uniqueCode);
    setIsCopied(true);
  };


 
  

  useEffect(() => {
    const fetchUserDomains = async () => {
      try {
        const userDomainsRef = collection(dbFirestore, 'users', userId, 'domains');
        const userDomainsQuery = query(userDomainsRef);
        const userDomainsSnapshot = await getDocs(userDomainsQuery);
        const userDomainsData = userDomainsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUserDomains(userDomainsData);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching user domains:', error);
        setIsLoading(false);
      }
    };

    fetchUserDomains();
  }, [userId]);

  const renderDomainList = () => {
  
    // Sort the domains by timestamp in descending order
  const sortedDomains = userDomains.slice().sort((a, b) => {
    // Assuming each domain entry has a timestamp field called 'timestamp'
    return new Date(b.timestamp) - new Date(a.timestamp);
  });
    const isSmallScreen = window.innerWidth <= 768;

    const domainsToDisplay = sortedDomains.slice(startIndex, endIndex);

    
    const thStyle = {
      backgroundColor: '#f0f0f0',
      padding: '8px',
      textAlign: 'left',
      borderBottom: '1px solid #ddd',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: isSmallScreen ? '150px' : '200px',
    };

    const tdStyle = {
      padding: '8px',
      borderBottom: '1px solid #ddd',
      backgroundColor: '#ffffff',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: isSmallScreen ? '100px' : '200px',
    };

    const TooltipbtnStyle = {
      backgroundColor: '#eeeeee',
      color: '#000000',
      padding: '2px 4px', // Adjust padding as needed for your content
      border: 'none',
      borderRadius: '5px', // Border radius for rounded corners
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center', // Center content vertically
      justifyContent: 'center', // Center content horizontally
     
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', // Optional box shadow for a raised effect
    };

    const toggleRowExpansion = (domainId) => {
      setExpandedRows((prevExpandedRows) => ({
        ...prevExpandedRows,
        [domainId]: !prevExpandedRows[domainId],
      }));
    };

    const tableStyle = {
      borderCollapse: 'collapse',
      width: '100%',
      textAlign: 'left',
  
    };

     // Media query to adjust styles for smaller screens
     if (window.innerWidth <= 768) {
      tdStyle.maxWidth = '100px'; // Adjust maxWidth for smaller screens
      thStyle.maxWidth = '150px'; // Adjust maxWidth for smaller screens
    }

    


    return (

      
      <table style={tableStyle}>
       
        <thead>
          <tr>
            <th style={thStyle}>Focus Area</th>
            
            {isSmallScreen && <th style={thStyle}></th>}
            {!isSmallScreen && (
              <>
                
                <th style={thStyle }>Type</th>
                <th style={thStyle}>Description</th>
                <th style={thStyle}>Date</th>
                <th style={thStyle}>Method</th>
                <th style={thStyle}>Tracking No</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {domainsToDisplay.map((domain, index) => {
            const rows = [
              <tr key={domain.id}>
                
                <td style={tdStyle}>{domain.domainName}</td>
               
                {isSmallScreen && (
                  <td style={tdStyle}>
                    <button style={ expandbtnStyle } className="expand-button" onClick={() => toggleRowExpansion(domain.id)}>
                      {expandedRows[domain.id] ? '-' : '+'}
                    </button>
                  </td>
                )}
                {!isSmallScreen && (
                  <>
                    
                    <td style={tdStyle}>{domain.requirement}</td>
                    <td style={{ padding: '8px', borderBottom: '1px solid #ddd', backgroundColor: '#ffffff', overflow: 'visible', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '200px', }}>
                      <Tooltip text={domain.description || 'No description is provided'}>
                        <button style={TooltipbtnStyle}>Read description</button>
                      </Tooltip>
                    </td>
                    <td style={tdStyle}>{domain.orderDate}</td>
                    <td style={tdStyle}>{domain.captureType}</td>
                    <td style={tdStyle}>{domain.id}</td>
                  </>
                )}
              </tr>
            ];
  
            if (isSmallScreen && expandedRows[domain.id]) {
              rows.push(
                <tr key={`${domain.id}-expanded`}>
                  <td colSpan={isSmallScreen ? '3' : '8'}>
                    <div>
                    <p style={{ color: 'grey', textAlign: 'center', lineHeight: '1.5' }}>
  <strong>{domain.domainName}</strong>
</p>
                    
                      <p><strong>Type:</strong> {domain.requirement}</p>
                      <p><strong>Description:</strong> {domain.description ? domain.description : 'No description is provided'}</p>
                      <p><strong>Date:</strong> {domain.orderDate}</p>
                      <p><strong>Method:</strong> {domain.captureType}</p>
                      <p><strong>Tracking No:</strong> {domain.id}</p>
              
                    </div>
                  </td>
                </tr>
              );
            }
  
            return rows;
          })}
        </tbody>
      </table>


    );
  };

  


  const renderScheduleList = () => {
  
    
    const isSmallScreen = window.innerWidth <= 768;

    const schedule = {
      // activity replace with focus area, time replace with activity
      Monday: { area: 'Information', activities: 'Stay upto date with latest in softwares, market and the industry at large.' },
      Tuesday: { area: 'Website development', activities: 'Analysis and Planning, product development, Content Strategy, User Interface (UI), Search Engine Optimization, etc. ' },
      Wednesday: { area: 'Management of service', activities: 'Content Management System, database management, Security Management, Performance Optimization,  User support, etc. ' },
      Thursday: { area: 'Marketing channels', activities: 'SEOs, Pay-Per-Click, Social Media Marketing, Content Marketing, Email/ SMS marketing, etc.' },
      Friday: { area: 'Other systems', activities: 'Get to know and use systems that are outside website development, but useful to improve your product/ service.' }
    };
    

    
    const thStyle = {
      backgroundColor: '#606666',
      padding: '8px',
      textAlign: 'left',
      borderBottom: '1px solid #ddd',
      color: '#ffffff',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: isSmallScreen ? '150px' : '200px',
    };

    const tdStyle = {
      padding: '8px',
      borderBottom: '1px solid #ddd',
      backgroundColor: '#eeeeee',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: isSmallScreen ? '100px' : '200px',
    };

    const TooltipbtnStyle = {
      backgroundColor: '#eeeeee',
      color: '#000000',
      padding: '2px 4px', // Adjust padding as needed for your content
      border: 'none',
      borderRadius: '5px', // Border radius for rounded corners
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center', // Center content vertically
      justifyContent: 'center', // Center content horizontally
     
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', // Optional box shadow for a raised effect
    };

    const toggleRowExpansion = (scheduleId) => {
      setExpandedRows((prevExpandedRows) => ({
        ...prevExpandedRows,
        [scheduleId]: !prevExpandedRows[scheduleId],
      }));
    };

    const tableStyle = {
      borderCollapse: 'collapse',
      width: '100%',
      textAlign: 'left',
  
    };

     // Media query to adjust styles for smaller screens
     if (window.innerWidth <= 768) {
      tdStyle.maxWidth = '100px'; // Adjust maxWidth for smaller screens
      thStyle.maxWidth = '150px'; // Adjust maxWidth for smaller screens
    }

    


    return (

      
      <table style={tableStyle}>
       
        <thead>
          <tr>
          <th style={thStyle}> Day</th>
          <th style={thStyle}> Focus Area</th>
            {isSmallScreen && <th style={thStyle}></th>}
            {!isSmallScreen && (
              <>
                 
                 <th style={thStyle}>  Activities</th>
                
              </>
            )}
          </tr>
        </thead>
        <tbody>
        
        {Object.entries(schedule).length > 0 ? (
  Object.entries(schedule).map(([day, { area, activities }]) => {
    const rows = [
      <tr key={day}>
        <td style={tdStyle}>{day}</td>
        <td style={tdStyle}>{area}</td>
        {isSmallScreen && (
          <td style={tdStyle}>
            <button style={expandbtnStyle} className="expand-button" onClick={() => toggleRowExpansion(day)}>
              {expandedRows[day] ? '-' : '+'}
            </button>
          </td>
        )}
        {!isSmallScreen && (
          <>
            <td style={tdStyle}>{activities}</td>
          </>
        )}
      </tr>
    ];

    if (isSmallScreen && expandedRows[day]) {
      rows.push(
        <tr key={`${day}-expanded`}>
          <td colSpan={isSmallScreen ? '2' : '3'}>
            <div>
              <p style={{ color: 'grey', textAlign: 'center', lineHeight: '1.5' }}>
                <strong>More on focus area</strong>
              </p>
              <p><strong>Activities:</strong> {activities}</p>
            </div>
          </td>
        </tr>
      );
    }

    return rows;
  })
) : (
  <tr>
    <td colSpan={isSmallScreen ? '2' : '4'} style={{ textAlign: 'center' }}><h3>You have not participate on the program</h3></td>
  </tr>
)}

        </tbody>
      </table>


    );
  };

  function getWeekNumber(timestamp) {
    const date = new Date(timestamp);
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = Math.floor((date - firstDayOfYear) / (24 * 60 * 60 * 1000));
    const weekNumber = Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
    return weekNumber;
}

  const containerStyle = {
    backgroundColor: '#fff',
    padding: '8px',
    textAlign: 'left',
    borderBottom: '1px solid #ddd',
    borderRadius: '10px',
    
    
  };

  const greyIconClass = (value) => {
    return value ? '' : 'greyIcon';
  };

  const topcoverStyle = {
    background: `url(${process.env.PUBLIC_URL}/fallback-cover-photo.png)`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '95%', // Set the width to 100% of the viewport width
    height: '190px', // Set the desired height for your background cover
    borderRadius: '5px',
    position: 'relative',
    top: '8vw', // Shift the element down by 50% of its own height
    transform: 'translateY(-60%)', // Correct the element's position by moving it up by 50% of its own height
    margin: '0 auto',
   
  };

  const BigclosePopupStyle = {
    position: 'fixed', 
    top: '4%', // Adjust the top position according to your design
    right: '25%', // Adjust the right position according to your design
    cursor: 'pointer', // Add a pointer cursor to indicate it's clickable
    background: 'white',
    color: 'blue',
  };

  const SmallclosePopupStyle = {
    alignItems: 'right',
    textAlign: 'right',
    cursor: 'pointer', // Add a pointer cursor to indicate it's clickable
    background: 'white',
    color: 'blue',
  };

  const titleStyle = {
    marginLeft: '3%',
  };

  const handleCopyClick = () => {
    copyToClipboard();
  };
  
  const copyStyle = {
  cursor: 'pointer',

  };
  
  const logoutStyle = {
    margin: '5px',
    padding: '6px 12px',
    fontSize: '12px',
    border: 'none',
    borderRadius: '5px',
    backgroundColor: '#4f4f4f',
    color: 'white',
    cursor: 'pointer'
  };

  const settingsbtnStyle = {
    margin: '5px',
    padding: '6px 12px',
    fontSize: '12px',
    border: 'none',
    borderRadius: '5px',
    backgroundColor: 'blue',
    color: 'white',
    cursor: 'pointer'
  };

  const expandbtnStyle = {
    margin: '5px',
    padding: '6px 12px',
    fontSize: '12px',
    border: 'none',
    borderRadius: '5px',
    backgroundColor: 'blue',
    color: 'white',
    cursor: 'pointer'
  };


  const NxtNavbuttonStyle = {

    position: 'absolute',
  
    right: '10px',
   
    
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'right', 
   
      margin: '0 5px', // Margin between buttons
      padding: '8px', // Padding inside buttons
      cursor: 'pointer', // Cursor style
      backgroundColor: 'transparent',
    };
  
    const PreNavbuttonStyle = {
  
      position: 'absolute',
  
    right: '40px',
   
    
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'right', 
   
      margin: '0 5px', // Margin between buttons
      padding: '8px', // Padding inside buttons
      cursor: 'pointer', // Cursor style
      backgroundColor: 'transparent',
    };
    
    const disabledNxtNavButtonStyle = {
      ...NxtNavbuttonStyle,
  
      cursor: 'not-allowed', // Change cursor to not-allowed for disabled buttons
      color: 'grey', // Set text color to grey for disabled buttons
  
    alignItems: 'center',
    justifyContent: 'center',
    };
  
    const disabledPreNavButtonStyle = {
      ...PreNavbuttonStyle,
  
      cursor: 'not-allowed', // Change cursor to not-allowed for disabled buttons
      color: 'grey', // Set text color to grey for disabled buttons
  
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    };
    
    
    const NaviconStyle = {
      marginRight: '5px', // Margin between icon and text
    };
  
    
    const handlePreviousPage = () => {
      const newStartIndex = Math.max(startIndex - 3, 0);
      setStartIndex(newStartIndex);
      setEndIndex(newStartIndex + 3);
    };

    const handleNextPage = () => {
      const newStartIndex = endIndex;
      const newEndIndex = Math.min(endIndex + 3, userDomains.length);
      setStartIndex(newStartIndex);
      setEndIndex(newEndIndex);
    };

    
  const linkStyle = {
    color: supportHours ? 'initial' : 'grey',
  };

    

    const switchbtnStyle = {
      margin: '5px',
      padding: '6px 12px',
      fontSize: '12px',
      border: '1px solid #ddd',
      borderRadius: '5px',
      backgroundColor: 'white',
      color: 'black',
      cursor: 'pointer'
    };

    

  return (

    <div>
  
    
      {isLoading && <LoadingSpinner />}
  
  {!isLoading && (
    
    <>
    <div className="section1-profile-cover">
   <div className="big-screen-button">
          
        </div>
      
    <div className="profile-top-cover" style={topcoverStyle}>
    
        </div>
        </div>
      <div className="section2-user-info">
    <div className="section-label">
      <div className="main-menu-profile">
      <div className="column-one-profile">
  
      <img
    src={profilePictureURL || process.env.PUBLIC_URL + '/fallback-profile-picture.png'}
    alt="Profile Picture"
    className="profile-picture"
    
  />
  
      </div>
  
      <div className="column-two-profile">
      <div className="small-screen-button">
          
        </div>
      <h3>{fullName ? fullName : 'User'}</h3>
      
     <p>Member since {memberSince}</p>
      <p> <Link to={`/account-settings/${userId}`} >
  <button style={ settingsbtnStyle }>
    Settings
  </button>
</Link>
<button style={ logoutStyle } onClick={handleLogout}>Logout</button>
    
          </p>
      
      </div>
    
      <div className="column-three-profile">
      <p className="profile-chat-contact">
  <div>
  <h4>Weekday: {day}</h4>
  </div>
</p>

<p className="profile-tel-contact">
  <h4>Focus: {topic}</h4>
</p>
<p className="profile-mail-contact">
<div><strong>Consult: </strong> 
<a href="https://wa.me/27789513047" target="_blank" rel="noopener noreferrer" class="support-link" style={linkStyle}><strong>Chat </strong></a> 
<a href="mailto:info@websiteservice.co.za" target="_blank" rel="noopener noreferrer" class="support-link" style={linkStyle}><strong>Email </strong></a>
<a href="tel:+2778 951 3047" target="_blank" rel="noopener noreferrer" class="support-link" style={linkStyle}><strong>Call </strong></a>
  </div>
 
  </p>
  
        </div>
        </div>
        </div>
        </div>
        <div className="section3-domain-portfolio">
          
        <h3 style={titleStyle}>WAB Program
        <button
  style={startIndex <= 0 ? disabledPreNavButtonStyle : PreNavbuttonStyle}
  onClick={handlePreviousPage}
  disabled={startIndex <= 0}
>
  <Icon icon="carbon:previous-filled" color={startIndex <= 0 ? 'grey' : 'black'} width="24" height="24" style={NaviconStyle} />

</button>

<button
  style={endIndex >= userDomains.length ? disabledNxtNavButtonStyle : NxtNavbuttonStyle}
  onClick={handleNextPage}
  disabled={endIndex >= userDomains.length}
>
  <Icon icon="carbon:next-filled" color={endIndex >= userDomains.length ? 'grey' : 'black'} width="24" height="24" style={NaviconStyle} />
</button>

        </h3>
        <div>
      <h4><button style={ switchbtnStyle } onClick={() => setView('schedule')}>Schedule</button>
      <button style={ switchbtnStyle } onClick={() => setView('activities')}>View activities</button> Week {weekNumber}</h4></div>
    
      {view === 'schedule' && renderScheduleList()}
        {view === 'activities' && renderDomainList()}
                
        </div>
        </>
    )}
      </div>
     
     
    
      
    );

    
  };

  const isSupportHours = () => {
    const now = new Date();
    const hours = now.getUTCHours() + 2; // Adjusting for SAST (UTC+2)
    return hours >= 8 && hours < 16;
  };

export default Profile;
